import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Mempelai, Divider, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/mini.aac'
import mask from '../assets/img/dinny/mask.svg'
import distance from '../assets/img/dinny/distance.svg'
import salaman from '../assets/img/dinny/salaman.svg'
import logoig from '../assets/img/dinny/logoig.svg'
import burung from '../assets/img/dinny/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import post from '../params/post'

let cmain = '#045A6E'
let mainfont = '#045A6E'
let black = 'rgb(38,38,38)'
export default class Halo extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.myRef = React.createRef()
    this.nama = React.createRef()
    this.alamat = React.createRef()
    this.pesan = React.createRef()
    this.state = {
      acara: [],
      days: '00',
      minutes: '00',
      hours: '00',
      seconds: '00',
      hide: true,
      hadir: true,
      err: [],
      submitted: '',
      sesi: 2
    }
  }
  componentDidMount() {
    AOS.init({
      // initialise with other settings
      duration: 2000
    });
    var countDownDate = new Date("12/05/2020").getTime();
    // Update the count down every 1 second
    var x = setInterval(() => {
      // Get today's date and time
      var now = new Date().getTime();
      // Find the distance between now and the count down date
      var distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        days: days, hours: hours, minutes: minutes, seconds: seconds
      })
    }, 1000);
  }

  play = () => {
    AOS.refresh()
    var snd = new Audio(music);
    snd.type = 'audio/aac';
    snd.play();
    this.setState({ hide: false })
    setTimeout(() => {
      var elmnt = document.getElementById('top');
      elmnt.scrollIntoView();
    }, 1000)
  }

  useQuery = () => {
    return new URLSearchParams(this.props.location.search);
  }
  handleSubmit = async () => {
    let err = []
    let local = localStorage.getItem('pesan')
    if (this.nama.current.value == "") {
      err.push('Nama tidak Boleh Kosong')
    }
    if (this.pesan.current.value == "") {
      err.push('Pesan tidak Boleh Kosong')
    }
    if (err.length == 0) {
      confirmAlert({
        message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              let send = await post(
                ` dari: "${this.nama.current.value}", hadir: "", jumlahhadir: "", kepada: "mini-adit", pesan:"${this.pesan.current.value}",alamat: ""`
              )
            if (send.status == 200) {
                this.setState({ submitted: true })
                localStorage.setItem('pesan', this.pesan.current.value)
                this.nama.current.value = ''
                this.pesan.current.value = ''
            }else{
                err.push('Koneksi Gagal')
            }
            }
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      });
    } else {
      this.setState({ err: err, submitted: false })
    }


  }
  render() {
    let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi } = this.state
    let slide = ["	https://i.ibb.co/n053rCy/MG-8218.jpg	",
      "	https://i.ibb.co/2PHS8ST/MG-8225.jpg	",
      "	https://i.ibb.co/D7NtPWQ/MG-8223.jpg	",
      "	https://i.ibb.co/PDkHcQH/MG-8748.jpg	",
      "	https://i.ibb.co/VNBSSvv/MG-8774.jpg	",
      "	https://i.ibb.co/r0N1ZTW/MG-8227.jpg	",
      "	https://i.ibb.co/GtzssZv/MG-8749.jpg	",
      "	https://i.ibb.co/XFHkQqt/MG-8772.jpg	",
      "	https://i.ibb.co/1Z2bJ5F/MG-8214.jpg	",
      "	https://i.ibb.co/8K0hMd8/MG-8221.jpg	",

    ]
    let slider = []
    slide.map(v => {
      slider.push(gambar(v, 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=520'))
    })
    let query = this.useQuery().get('name');
    query = query ? cap(query) : ''

    return (
      <>
        <Helm
          title='Undanganku - Mini & Adit'
          desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
          logo={logo}
          img={'https://i.ibb.co/VNBSSvv/MG-8774.jpg'}
          url='https://undanganku.me/mini-adit'
        />

        <div id='gold5' style={{
          backgroundImage: `url(${'https://i.ibb.co/gmz96rb/Background-Putih.jpg'})`,
          backgroundSize: 'cover', backgroundPosition: 'center'
        }}>
          <div className='w-100' style={{
            overflow: 'hidden', maxWidth: '100vw',
            backgroundColor: 'transparent'
          }}>
            <Container fluid id='g3-header' className='relative' style={{
              backgroundImage: `url('https://i.ibb.co/xsLF6Yk/Modal.jpg')`
            }}>
              <Item>
                <Col xs={12} md={4} className='m-2 m-md-0 '>
                  <img className='img-fluid w-100 p-2'
                    src={gambar('https://i.ibb.co/ggZfLt9/Logo-1.png')} data-aos="fade-left" />
                </Col>
              </Item>
              <Item>
                {
                  <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                    Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                }</Item>
              <Row className='justify-content-center'>
                <div onClick={() => { this.play() }}
                 
                  className={`col-md-4 button roboto-slab text-center btn  ${hide ? 'show' : 'hide'}`}
                  style={{ marginTop: 0 }}>
                  Open Invitation
                  </div>
              </Row>
            </Container>

            <div className={hide ? 'd-none' : 'd-block'}>
              <div id="top" style={{ backgroundColor: 'transparent' }}>
                <Container className="dinny px-3 pt-5 ">
                  <Item>
                    <p className="fs24 text-center cblack">
                      <span className="fs16">
                        Dan firman-Nya: Sebab itu laki-laki akan meninggalkan ayah dan ibunya dan bersatu
                        dengan isterinya, sehingga keduanya itu menjadi satu daging.
                        Demikianlah mereka bukan lagi dua, melainkan satu.
                        Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia."
                        <br />(Matius 19:5-6)
                        </span>
                    </p>
                  </Item>
                  <Item>
                    <Col xs={6} sm={2}>
                      <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                    </Col>
                  </Item>
                  <Item>
                    <p className='text-center p-2 px-4 ' style={{ color: mainfont }}>
                      The Intimate Wedding of Mini & Adit
                              </p>
                  </Item>
                </Container>
                <Container id='sectiongold55' className="py-5 dinny" >

                  <Item>
                    <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                      <div className='kotak mr-lg-2'>
                        <Item>
                          <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                            Mini
                      </h1>
                        </Item>
                        <Item>
                          <Col xs={6}>
                            <img src={gambar('https://i.ibb.co/KKfRQYG/Individual-2-1.jpg', 90)} className='img-fluid w-100' />
                          </Col>
                        </Item>
                        <Item>
                          <h1 className="py-3" style={{
                            fontSize: '24px', fontFamily: "'Marck Script', cursive",
                            color: mainfont
                          }}>
                            Miniati Wulandari
                      </h1>
                        </Item>
                        <Item>
                          <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                            <b>The Daughter of: </b><br />
                            Bpk. Milunutu
<br />
                        &<br />
                        Ibu Yesleni
                      </p>
                        </Item>
                        <Item>
                          <Col xs={3} sm={2} 
                            onClick={() => { window.open('https://instagram.com/miniati.wulandari') }}>
                            <img src={logoig} className='img-fluid w-100 btn p-0' />
                          </Col>
                        </Item>
                      </div>
                    </div>
                    <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                      <div className='kotak mr-lg-2'>
                        <Item>
                          <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                            Adit
                      </h1>
                        </Item>
                        <Item>
                          <Col xs={6}>
                            <img src={gambar('https://i.ibb.co/ZN8pKm6/Individual-1-1.jpg', 90)} className='img-fluid w-100' />
                          </Col>
                        </Item>
                        <Item>
                          <h1 className="py-3" style={{ fontSize: '24px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                            Aditya Gonang
                      </h1>
                        </Item>
                        <Item>
                          <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                            <b>The Son of : </b><br />
                            Bpk. Yunike Agau

                        <br />
                        &<br />
                        Ibu Hawun S. Yohanes
                      </p>
                        </Item>
                        <Item>
                          <Col xs={3} sm={2} 
                            onClick={() => { window.open('https://instagram.com/terangterik') }}>
                            <img src={logoig} className='img-fluid w-100 btn p-0' />
                          </Col>
                        </Item>
                      </div>
                    </div>
                  </Item>
                </Container>
                <Container fluid className="text-center px-4 dinny" style={{ color: black }} >
                  <Item>
                    <p className="fs16">
                      Yang akan dilaksanakan pada:
                    </p>
                  </Item>
                  <Item>
                    <p className="fs20">
                      <b>
                        SABTU <span className="fs36">05</span> DES 2020
                      </b>
                    </p>
                  </Item>
                  <Item>
                    <p className="fs20 col-sm-4" style={{ color: mainfont }}>
                      <b>Pemberkatan</b><br />
                      <span className="cblack fs16">
                        <b>
                          05 Desember 2020
                      <br />
                        </b>
                      16.00 WITA - Selesai <br />
                        <b>
                          Gereja GKE Eppata Banjarmasin
                      </b>
                      </span>

                    </p>
                    <p className="px-3 d-none d-sm-block" style={{ color: mainfont, fontSize: '72px' }}>
                      \
                    </p>
                    <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                    </div>
                    <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: mainfont }}>
                      <b>Resepsi</b><br />
                      <span className="cblack fs16">
                        <b>
                          05 Desember 2020
                      <br />
                        </b>
                      18.30 WITA - Selesai
                      <br />
                        <b>Treepark Hotel banjarmasin
                    </b> <br />
                    Banjarmasin Selatan, Kota Banjarmasin, Kalimantan Selatan, 70245
                      </span>


                    </p>
                  </Item>
                  <Item>
                    <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                    </div>
                  </Item>
                  <Item>
                    <p className="fs20 col-sm-4" style={{ color: mainfont }}>
                      <b>Pemenuhan Hukum Adat Dayak Ma'anyan</b><br />
                      <span className="cblack fs16">
                        <b>
                          04 Desember 2020
                      <br />
                        </b>
                      16.00 WITA - Selesai <br />
                        <b>
                          Aula Gereja GKE Hosiana Banjarmasin
                      </b>
                      </span>

                    </p>
                  </Item>


                  <Item>
                    <Col xs={10} sm={3}
                      style={{
                        border: `2px solid ${cmain}`,
                        borderRadius: '10px'
                      }}
                      onClick={() => {
                        window.open("https://goo.gl/maps/3LvVYMadCDUFmKn17")
                      }}
                      className="p-2 mx-sm-2 btn ">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                          style={{ width: "10%", height: 'auto' }} />
                        <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                          <b>Get Direction</b>
                        </p>
                      </Item>
                    </Col>
                    <Col 
                      onClick={() => window.open('https://calendar.google.com/event?action=TEMPLATE&tmeid=MnY2NGdkY29vZXRrNmdhazhtZ3BiZXY3ZW8gYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com')}
                      xs={10} sm={3}
                      style={{
                        border: `2px solid ${cmain}`,
                        borderRadius: '10px'
                      }}
                      className="p-2 mx-sm-2 mt-3 mt-sm-0 btn p-0">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                          style={{ width: "10%", height: 'auto' }} />
                        <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                          <b>Add to Calendar</b>
                        </p>
                      </Item>
                    </Col>
                  </Item>
                </Container>
                <Container id='sectiongold57'>
                  <div className='py-3'>

                    <Item>
                      <div data-aos="fade-left" data-aos-duration="1000"
                        className='col-10 col-lg-8 kotak' style={{ backgroundColor: cmain }}>
                        <Item>
                          <div className='item'>
                            <Item>
                              <div>
                                {days}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Days
                                </span>
                            </Item>
                          </div>
                          <div className='dot'>:</div>
                          <div className='item'>
                            <Item>
                              <div>
                                {hours}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Hours
                      </span>
                            </Item>
                          </div>
                          <div className='dot'>:</div>
                          <div className='item'>
                            <Item>
                              <div >
                                {minutes}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Mins
                      </span>
                            </Item>
                          </div>
                          <div className='dot' >:</div>
                          <div className='item'>
                            <Item>
                              <div>
                                {seconds}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Secs
                      </span>
                            </Item>
                          </div>
                        </Item>

                      </div>
                    </Item>
                  </div></Container>
                <Container className="text-center dinny">
                  <Item>
                    <Col className="py-3 px-sm-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                      <h1 className="fs30"
                        style={{

                          fontFamily: "'Marck Script', cursive",
                          color: mainfont
                        }}>
                        New Normal Rules
                          </h1>
                      <Item>
                        <Col xs={12}>
                          <p className="cblack text-center w-100 fs16">
                            Demi keamanan dan kenyamanan bersama, para hadirin undangan dihimbau untuk memenuhi beberapa peraturan berikut:
                          </p>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={mask} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              Gunakan Masker
                            </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={distance} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              jaga Jarak
                            </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={salaman} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              Cukup Bersalaman tanpa Bersentuhan
                      </p>
                          </Row>
                        </Col>
                      </Item>
                    </Col>
                  </Item>

                  <Item>
                    <Col>
                    </Col>

                  </Item>

                </Container>


                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                  <Slider slide={slider} />
                </Container>
                <Container id='sectiongold56'>
                  <div className='pt-3'>

                    <div data-aos={`fade-right`} data-aos-duration="2000">
                      <Item>
                        <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                          <Item>
                            <p className='text-center p-2 px-4'>
                              Whatever our souls are made of, his and mine are the same. <br />- Emily Brontë -
                              </p>
                          </Item>
                        </div>

                      </Item>
                    </div>
                  </div>
                </Container>

                <Container id='sectiongold58' >

                  <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                    <Item>
                      <Col xs={4} lg={2}>
                        <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                      </Col>
                    </Item>
                    <Item>
                      <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                        <Item>
                          <h1 style={{
                            fontFamily: '"Marck Script", cursive',
                            color: mainfont
                          }}>
                            Send Your Wishes
                    </h1>
                        </Item>
                        <Item>
                          <form className="col-12 w-100">
                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' defaultValue={query ? query : ''} />
                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                            <Item>
                              <div id="formradio">
                                <div class="custom_radio row justify-content-center">
                                  <div onClick={() => {
                                    this.setState({ hadir: true })
                                  }
                                  }>
                                    <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                    <label for="featured-1">Hadir</label>
                                  </div>
                                  <div onClick={() => {
                                    this.setState({ hadir: false })
                                  }
                                  } className="pl-5">
                                    <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                    <label for="featured-2"

                                    >Tidak Hadir</label>
                                  </div>

                                </div>
                              </div>
                            </Item>

                            <Item>
                              <Col xs={12} className=''>
                                {
                                  submitted == true ? (
                                    <Alert variant='success' style={{ fontSize: '16px' }}>
                                      Pesan anda sudah disampaikan
                                    </Alert>) : (submitted === false ? (
                                      <Alert variant='danger' style={{ fontSize: '16px' }}>
                                        {
                                          err.map(val => {
                                            return (
                                              <li>{val}</li>
                                            )
                                          })
                                        }

                                      </Alert>
                                    ) : false)
                                }

                              </Col>
                            </Item>
                            <Item>
                              <div  className='col-6 button rounded btn p-0'
                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain }} no> Kirim </div>
                            </Item>
                          </form>
                        </Item>
                      </div>
                    </Item>
                  </div>
                </Container>
                <Container className='text-center mb-5' id='sectiongold59'>
                  <Item>
                    <h1 data-aos="zoom-in" data-aos-duration="1000" style={{ color: mainfont }}>
                      Possible Wedding
              </h1>
                  </Item>
                  <Item>
                    <h2 data-aos="zoom-in" data-aos-duration="1000" style={{ color: mainfont }}>
                      Digital Invitation
              </h2>
                  </Item>
                  <Item>
                    <div className='col-3 col-lg-1 btn p-0' onClick={() => { window.open('https://instagram.com/possiblewedding') }} >
                      <img src={logoig} className='img-fluid w-100'
                      />
                    </div>
                  </Item>

                </Container>
              </div>
            </div>
          </div>
        </div>

      </>


    )
  }
}

